<template>
  <body class="bg-slate-900 min-h-full">

    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
    
      
      <div class="w-full max-w-md mx-auto p-6">
        <div class="mt-7 border rounded-xl shadow-sm bg-gray-800 border-gray-700">
            <div class="p-4 sm:p-7">
            <div class="text-center">
                <h1 class="block text-2xl font-bold text-white">Sign in</h1>
                <p class="mt-2 text-sm text-gray-400">
                Don't have an account yet?
                <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToSignUpPage">
                    Sign up here
                </a>
                </p>
            </div>

            <div class="mt-5">
                <!-- Form -->
                <form @submit.prevent="submitLogin">
                <div class="grid gap-y-4">
                    <!-- Form Group -->
                    <div>
                    <label for="email" class="block text-sm mb-2 text-white">Email address</label>
                    <div class="relative">
                        <input v-model="email" type="email" id="email" name="email" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400" required aria-describedby="email-error">
                        <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                        <svg class="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                        </div>
                    </div>
                    <p class="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                    </div>
                    <!-- End Form Group -->

                    <!-- Form Group -->
                    <div>
                    <div class="flex justify-between items-center">
                        <label for="password" class="block text-sm mb-2 text-white">Password</label>
                        <a class="text-sm text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToForgotPasswordPage">Forgot password?</a>
                    </div>
                    <div class="relative">
                        <input v-model="password" type="password" id="password" name="password" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400" required aria-describedby="password-error">
                        <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                        <svg class="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                        </div>
                    </div>
                    <p class="hidden text-xs text-red-600 mt-2" id="password-error">8+ characters required</p>
                    </div>
                    <!-- End Form Group -->

                    <!-- Checkbox -->
                    <!-- <div class="flex items-center">
                    <div class="flex">
                        <input id="remember-me" name="remember-me" type="checkbox" class="shrink-0 mt-0.5 rounded text-blue-600 pointer-events-none focus:ring-blue-500 bg-gray-800 border-gray-700 checked:bg-blue-500 checked:border-blue-500 focus:ring-offset-gray-800">
                    </div>
                    <div class="ml-3">
                        <label for="remember-me" class="text-sm text-white">Remember me</label>
                    </div>
                    </div> -->
                    <!-- End Checkbox -->

                    <button type="submit" class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-primary text-white bg-primary:hover focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm focus:ring-offset-gray-800">Sign in</button>
                    <div v-if="loginError" class="max-w-xs border rounded-md shadow-lg bg-gray-800 border-gray-700" role="alert">
                      <div class="flex p-4">
                        <div class="flex-shrink-0">
                          <svg class="h-4 w-4 text-red-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                          </svg>
                        </div>
                        <div class="ml-3">
                          <p class="text-sm text-gray-400">
                            {{ errorMessage }}
                          </p>
                        </div>
                      </div>
                    </div>

                </div>
                </form>
                <!-- End Form -->
            </div>
            </div>
        </div>
      </div>
      <FooterComponent />      
    </div>
  </body>
</template>

<script>
import FooterComponent from '@/components/Layout/FooterComponent.vue';
import HeaderComponent from '@/components/Layout/HeaderComponent.vue';

export default {
  data() {
    return {
      message: 'Hello from my Vue component!',
      email: '',
      password: '',
      loginError: false,
      errorMessage: 'Your email or password is incorrect, please try again.',
    };
  },
  components: {
    FooterComponent,
    HeaderComponent
  },
  computed: {
      intent() {
          return this.$store.state.intent;
      }
  },
  methods: {
    navigateToSignUpPage: function () {
      this.$router.push('/signup');
    },
    navigateToForgotPasswordPage: function () {
      this.$router.push('/forgot-password');
    },
    navigateToUserDashboardPage: function () {
      this.$router.push('/my-games');
    },
    submitLogin: async function () {
      // Scrolls to the bottom in case there's an error to see
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);

      try {
        const result = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',  // Necessary for passing of login cookies from backend
          body: JSON.stringify({
            email: this.email,
            password: this.password
          })
        });

        const data = await result.json();
        
        if (result.status === 200) {
          // Assuming the token is returned in a field named 'token' in the JSON response
          const token = data.jwt;
          localStorage.setItem('authToken', token);
          // Login was successful, so we chek the user's intent and redirect them to the appropriate page 
          if (this.intent === 'recommendations') {
            this.$router.push('/recommendations');
          } else if (this.intent === 'all-games') {
            this.$router.push('/all-games');
          } else if (this.intent === 'news') {
            this.$router.push('/news');
          } else {
            this.$router.push('/my-games');
          }
        } else {
          this.loginError = true;
        }

      } catch (e) {
        if (e.message === 'Network Error' || e.message === 'Failed to fetch') {
          // The server is down
          this.errorMessage = 'Our servers are down right now as Mario is taking a shower. Please try again later or contact support.'
        } else {
          this.errorMessage = 'Oops, something went wrong with the login process or an account with this e-mail does not exist. Please try again or contact support.'
        }
        this.loginError = true;
        return;
      }


    }
  }
}
</script>

<style scoped>

</style>
