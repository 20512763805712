import('preline')
import VueGtag from "vue-gtag";

import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';

import AboutPage from '@/components/AboutPage.vue'
import AllGamesPage from '@/components/AllGamesPage.vue'
import ForgotPasswordPage from '@/components/Authentication/ForgotPasswordPage.vue'
import GameDetailsPage from '@/components/GameDetailsPage.vue'
import GameReviewsPage from '@/components/GameReviewsPage.vue'
import GameTipsPage from '@/components/GameTipsPage.vue'
import GamePickerPage from '@/components/GamePickerPage.vue'
import GameRecommendationsChoosePage from '@/components/GameRecommendationsChoosePage.vue'
import GameRecommendationsSelfPage from '@/components/GameRecommendationsSelfPage.vue'
import GameRecommendationsFriendsPage from '@/components/GameRecommendationsFriendsPage.vue'
import GameRecommendationsFromFavoriteGames from "./components/GameRecommendationsFromFavoriteGames.vue";
// import JoinPremiumSuccessPage from '@/components/Authentication/JoinPremiumSuccessPage.vue'
import LoginPage from '@/components/Authentication/LoginPage.vue'
// import PremiumPricingPage from '@/components/PremiumPricingPage.vue'
import NewsPage from '@/components/NewsPage.vue'
import ProfilePage from '@/components/Authentication/ProfilePage.vue'
import SignUpPage from '@/components/Authentication/SignUpPage.vue'
import UserDashboardPage from '@/components/UserDashboardPage.vue'

import App from './App.vue'

import store from './store';

import './styles/app.css'


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faArrowDown, faArrowUp, faBinoculars, faCheck, faCheckToSlot, faCircleUser, 
    faHeart, faGamepad, faGear, faGift, faHourglass, faHourglassHalf, 
    faChevronLeft, faChevronRight, faMagnifyingGlassPlus, 
    faStar, faStarOfLife, faThLarge, faTrophy, faTrash, faUser, faUsers, faX
} from '@fortawesome/free-solid-svg-icons';

import { faXbox } from '@fortawesome/free-brands-svg-icons';

/* add icons to the library */
library.add(
    faArrowDown,
    faArrowUp,
    faBinoculars,
    faCheck,
    faCheckToSlot,
    faCircleUser,
    faHeart,
    faHourglassHalf,
    faGamepad, 
    faGear, 
    faGift,
    faHourglass,
    faChevronLeft, 
    faChevronRight, 
    faMagnifyingGlassPlus,
    faStar, 
    faStarOfLife, 
    faThLarge,
    faTrash,
    faTrophy,
    faUser,
    faUsers,
    faX,
    faXbox
)

const VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID

// Define your routes
const routes = [
    { path: '/', component: GamePickerPage },
    { path: '/about', component: AboutPage },
    { path: '/my-games', component: UserDashboardPage },
    { path: '/login', component: LoginPage },
    { path: '/profile', component: ProfilePage},
    { path: '/signup', component: SignUpPage },
    { path: '/forgot-password', component: ForgotPasswordPage },
    { path: '/recommendations', component: GameRecommendationsChoosePage },
    { path: '/recommendations-for-self', component: GameRecommendationsSelfPage },
    { path: '/recommendations-for-friends', component: GameRecommendationsFriendsPage },
    { path: '/recommendations-from-favorite-games', component: GameRecommendationsFromFavoriteGames },
    { path: '/all-games', component: AllGamesPage },
    { path: '/news', component: NewsPage },
    {
        path: '/game/:gameTitle/:gameId',
        props: true,
        children: [
            {
              path: 'details', // /game/:gameTitle/:gameId/details
              name: 'GameDetailsPage',
              component: GameDetailsPage,
              props: true,
            },
            {
              path: 'reviews', // /game/:gameTitle/:gameId/reviews
              name: 'GameReviewsPage',
              component: GameReviewsPage,
              props: true,
            },
            {
              path: 'tips', // /game/:gameTitle/:gameId/tips
              name: 'GameTipsPage',
              component: GameTipsPage,
              props: true,
            },
          ]
    }
    // TODO: To reintroduce once we bring back premium
    // { path: '/join-premium-success', component: JoinPremiumSuccessPage },
    // { path: '/premium-pricing', component: PremiumPricingPage },
];

// Create the router instance and pass the routes option
const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
});


createApp(App)
    .use(VueGtag, {
        config: { id: VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID }
    })
    .use(router) // Add this line to integrate vue-router
    .use(store)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount("#app");
