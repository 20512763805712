import { createStore } from 'vuex';

export default createStore({
    state: {
        authenticated: false,
        intent: null,
        gamerId: null,
        gameKarmaPoints: null,
    },
    mutations: {
        SET_AUTH: (state, auth) => state.authenticated = auth,
        SET_INTENT: (state, intent) => state.intent = intent,
        SET_GAMER_ID: (state, gamerId) => state.gamerId = gamerId,
        SET_GAME_KARMA_POINTS(state, gameKarmaPoints) {
            state.gameKarmaPoints = gameKarmaPoints;
        }
    },
    actions: {
        setAuth: ({ commit }, auth) => commit('SET_AUTH', auth),
        setIntent: ({ commit }, intent) => commit('SET_INTENT', intent),
        setGamerId: ({ commit }, gamerId) => commit('SET_GAMER_ID', gamerId),
        setGameKarmaPoints: ({ commit }, gameKarmaPoints) => commit('SET_GAME_KARMA_POINTS', gameKarmaPoints)
    }
})
