<template>
  <body class="bg-slate-900 min-h-full">
    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">
      <HeaderComponent />
        <div class="w-full max-w-md mx-auto p-6">
          <div class="mt-7 border rounded-xl shadow-sm bg-gray-800 border-gray-700">
          <div class="p-4 sm:p-7">
              <div class="text-center">
              <h1 class="block text-2xl font-bold text-white">Forgot password?</h1>
              <p class="mt-2 text-sm text-gray-400">
                  Remember your password?
                  <a class="text-primary decoration-2 hover:underline font-medium cursor-pointer" @click="navigateToLoginPage">
                    Sign in here
                  </a>
              </p>
              </div>

              <div class="mt-5">
                <form v-if="!this.passwordInitiationSuccess" @submit.prevent="this.resetPasswordInitiation()">
                  <div class="grid gap-y-4">
                    <div>
                        <label for="email" class="block text-sm mb-2 text-white">Email address</label>
                        <div class="relative">
                        <input v-model="this.userEmail" type="email" id="email" name="email" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400" required aria-describedby="email-error">
                        <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                            <svg class="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                            </svg>
                        </div>
                        </div>
                        <p class="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                    </div>

                    <button type="submit" class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm focus:ring-offset-gray-800">Reset password</button>
                  </div>
                </form>

                <form v-if="this.passwordInitiationSuccess" @submit.prevent="this.resetPasswordFinalize()">
                  <div class="grid gap-y-4">
                    <div>
                        <label for="resetcode" class="block text-sm text-left mb-2 text-white">Password Reset Code (check email)</label>
                        <div class="relative">
                          <input v-model="this.passwordResetCode" id="resetcode" name="resetcode" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400" required>
                        </div>
                    </div>
                    <div>
                        <label for="newpass" type="password" class="block text-sm text-left mb-2 text-white">New Password</label>
                        <input v-model="this.newPassword" type="password" id="newpass" name="newpass" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400" required aria-describedby="password-error">
                        <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                        <svg class="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                        </div>
                    </div>

                    <div>
                        <label for="newpassconfirm" type="password" class="block text-sm text-left mb-2 text-white">New Password (again for confirmation)</label>
                        <input v-model="this.newPasswordConfirmation" type="password" id="newpassconfirm" name="newpassconfirm" class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-gray-700 border-gray-700 text-gray-400" required aria-describedby="password-error">
                        <div class="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                        <svg class="h-5 w-5 text-red-500" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" aria-hidden="true">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        </svg>
                        </div>
                    </div>

                    <button type="submit" class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-primary-hover focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm focus:ring-offset-gray-800">Submit</button>
                  </div>
                </form>

                <div v-if="this.errorOccurred" class="max-w-xs border rounded-md shadow-lg bg-gray-800 border-gray-700" role="alert">
                  <div class="flex p-4">
                    <div class="flex-shrink-0">
                      <svg class="h-4 w-4 text-red-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                    </div>
                    <div class="ml-3">
                      <p class="text-sm text-gray-400">
                        {{ this.errorMessage }}
                      </p>
                    </div>
                  </div>
                </div>

                <div v-if="this.passwordInitiationSuccess" class="max-w-xs border rounded-md shadow-lg bg-gray-800 border-gray-700" role="alert">
                  <div class="flex p-4">
                    <div class="flex-shrink-0">
                      <svg class="h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M5.293 9.293l1.414 1.414L12.207 6l-1.414-1.414L6.707 8.586l-1.414-1.414L3.879 8.586z"/>
                      </svg>
                    </div>
                    <div class="ml-3">
                      <p class="text-sm text-gray-400">
                        Check your e-mail for your reset code, then enter it above along with a new password of your choosing. If the email doesn't arrive, check spam or contact <a class="underline" href="/about">Support</a>.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
          </div>

          <!-- POP-UP MODAL FOR FORGOT PASSWORD COMPLETION (STARTS) -->
          <div id="hs-can-log-in-modal" class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex justify-center items-center inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm">
            <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3">
              <div class="flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
                <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
                  <h3 class="font-bold text-white">
                    Password Reset Successful
                  </h3>
                  <button type="button"  @click="navigateToLoginPage" class="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-gray-700 focus:ring-offset-gray-800" data-hs-overlay="#hs-can-log-in-modal">
                    <span class="sr-only">Close</span>
                    <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                    </svg>
                  </button>
                </div>
                <div class="p-4 overflow-y-auto">
                  <p class="mt-1 text-gray-400">
                    You may now sign in with your new credentials.
                  </p>
                </div>
                <div class="p-4 overflow-y-auto">
                  <button type="button" @click="navigateToLoginPage" class="py-[.688rem] px-4 mx-2 inline-flex justify-center items-center gap-2 rounded-md border-2 font-semibold text-white bg-primary bg-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm border-gray-700" data-hs-overlay="#hs-can-log-in-modal">
                    Sign in here
                  </button>
                </div>
                <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-gray-700">
                  <button type="button"  @click="navigateToLoginPage" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-slate-900 hover:bg-slate-800 border-gray-700 text-gray-400 hover:text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-can-log-in-modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- POP-UP MODAL FOR FORGOT PASSWORD COMPLETION (ENDS) -->

          <!-- BUTTON THAT HANDLES THE LOGIN MODAL -->
          <button class="display-none" type="button" ref="loginModalBtn" data-hs-overlay="#hs-can-log-in-modal"></button>

          </div>
        </div>
      <FooterComponent />
    </div>
  </body>
</template>

<script>
import axios from 'axios';

import FooterComponent from '@/components/Layout/FooterComponent.vue';
import HeaderComponent from '@/components/Layout/HeaderComponent.vue';

export default {
  data() {
    return {
      userEmail: null,
      errorOccurred: false,
      errorMessage: 'There was an issue with the forget password process, please try again later or contact support.',
      passwordInitiationSuccess: false,
      passwordResetCode: null,
      newPassword: null,
      newPasswordConfirmation: null
    };
  },
  components: {
    FooterComponent,
    HeaderComponent
  },
  methods: {
    navigateToLoginPage: function () {
      // We first click the button to toggle the modal off
      const elem = this.$refs.loginModalBtn
      elem.click()

      // Then we go to login
      this.$router.push('/login');
    },
    resetPasswordInitiation: function () {
      // Scrolls to the bottom in case there's an error to see
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);

      this.errorOccurred = false;

      if (!this.userEmail) {
        this.errorMessage = 'Please provide a valid email address!';
        this.errorOccurred = true;

        return;
      }

      // Define the payload data
      const inputData = {
        user_email: this.userEmail
      };

      // Make the API call
      axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/reset-password-initiation/`, inputData)
        .then(response => {
          // Handle success, maybe provide feedback to the user or update the UI
          console.log(response.data);
          this.passwordInitiationSuccess = true;
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            // Handle 401 Unauthorized error
            this.errorMessage = 'A user with this e-mail address was not found, please try again.';
          } else if (error.message === 'Network Error' || error.message === 'Failed to fetch') {
            // Servers are down
            this.errorMessage = 'Our servers are down right now as Mario is taking a shower. Please try again later or contact support.';
          } else {
            // Handle other types of errors
            this.errorMessage = 'There was an issue with the forget password process, please try again later or contact support.';
          }

          this.errorOccurred = true;
        });
    },
    resetPasswordFinalize: function () {
      // Scrolls to the bottom in case there's an error to see
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);

      this.errorOccurred = false;

      if (!this.passwordResetCode || !this.newPassword || !this.newPasswordConfirmation) {
        this.errorMessage = 'Please provide a proper reset code, password, and password combination!';
        this.errorOccurred = true;

        return;
      }

      if (!(this.newPassword === this.newPasswordConfirmation)) {
        this.errorMessage = 'Please make sure your password and password confirmation matches!';
        this.errorOccurred = true;

        return;
      }

      // Define the payload data
      const inputData = {
        user_email: this.userEmail,
        reset_code: this.passwordResetCode,
        new_password: this.newPassword,
      };

      // Make the API call
      axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/reset-password-finalize/`, inputData)
        .then(response => {
          // Handle success, maybe provide feedback to the user or update the UI
          console.log(response.data);

          // Password reset was successful so we show the modal
            const elem = this.$refs.loginModalBtn
            elem.click()
        })
        .catch(error => {
          
          if (error.response && error.response.status === 401) {
            // Handle 401 Unauthorized error
            this.errorMessage = 'The reset code was invalid, please try again.';
          } else {
            // Handle other types of errors
            this.errorMessage = 'There was an issue with the password reset process, please try again later or contact support.';
          }

          this.errorOccurred = true;
        });

    }
  }
}
</script>

<style scoped>
</style>
