<template>

  <body class="bg-slate-900 min-h-full">

    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">

      <HeaderComponent />

      <!-- ========== MAIN CONTENT ========== -->
      <main role="main" class="">
        <div class="grid place-items-center px-4 sm:px-6 lg:px-8">
          <div>
            <div class="max-w-lg border shadow-sm rounded-xl hover:shadow-lg transition bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
              <div v-if="this.gameChosen && this.gameChosen['images'].length > 0" class="bg-gray-700 rounded-t-xl">
                <div v-if="!this.choosingGameInProcess">
                  <div v-for="(image, index) in this.gameChosen['images']" :key="index" class="carousel-item relative" v-show="currentGameImageShown === index">
                    <button @click="prevGameImageShown" type="button" class="absolute bottom-2 left-2 inline-flex flex-shrink-0 justify-center items-center opacity-60 gap-2 h-[3rem] w-[3rem] rounded-full border border-transparent bg-primary text-white hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-lg font-bold focus:ring-offset-gray-800">
                      <font-awesome-icon icon="fa-solid fa-chevron-left" />
                    </button>
                    <button @click="prevGameImageShown" type="button" class="absolute bottom-2 right-2 inline-flex flex-shrink-0 justify-center items-center opacity-60 gap-2 h-[3rem] w-[3rem] rounded-full border border-transparent bg-primary text-white hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-lg font-bold focus:ring-offset-gray-800">
                      <font-awesome-icon icon="fa-solid fa-chevron-right" />
                    </button>
                    <img class="w-full h-auto aspect-video rounded-t-xl object-contain" :src="image" alt="An image showing the game">
                  </div>
                </div>
                <div v-else class="w-full h-auto aspect-video rounded-t-xl border-2 border-green-500">
                  <div>&nbsp;</div>
                  <div class="inline-block p-16 animate-spin w-6 h-6 border-[3px] border-current border-t-transparent text-primary rounded-full" role="status" aria-label="loading"></div>
                </div>
              </div>
              <div v-else>
                <img class="w-full h-auto aspect-video rounded-t-xl" src="../assets/reccs-from-fav-games.png" alt="Mystery placeholder box for the game">
              </div>
              <div class="px-4 md:px-5">
                <div>
                  <div v-if="this.gameChosen && this.gameChosen['title']" >
                    <div class="mt-3 text-xl font-bold text-white h-12">
                      {{ this.gameChosen['title'] }}
                      <font-awesome-icon 
                        @click="viewGameDetailsPage(this.gameChosen['xboxApiId'], this.gameChosen['title'])" 
                        class="text-xs text-primary cursor-pointer" 
                        icon="fa-solid fa-magnifying-glass-plus" />
                    </div>
                    <div v-if="gameChosen['leavingSoon']" class="text-primary font-bold underline">
                      Leaving {{ this.gameChosen['leavingSoonDate'] }}
                    </div>
                    <div v-if="isRecentlyReleased(gameChosen)" class="text-xs text-primary font-bold underline pb-1">
                      Just Released
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium py-2">IGN Score</span>
                      <div class="ign-score-container">
                        <img src="../assets/ign-score-background.png" alt="IGN score logo which is a red hexagon">
                        <div class="ign-score">{{ this.gameChosen['ignScore'] }}</div>
                      </div>
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium">Gameplay Hours</span>
                      <span>{{ this.gameChosen['ignDurationHours'] }}</span>
                    </div>
                    <div class="flex justify-between text-white text-left py-2">
                      <span class="font-medium">Genres&nbsp;</span> 
                      <span>{{ this.gameChosen['ignAllPossibleGenres'].join(', ') }}</span>
                    </div>
                    <div class="flex justify-between text-white text-left">
                      <span class="font-medium">Links&nbsp;</span>
                      <span v-if="isLargeScreen" class="mt-1">
                        <a class="hover:underline" :href="metacriticUrl" target="_blank">Metacritic</a>, 
                        <a class="hover:underline" :href="howLongToBeatUrl" target="_blank">HowLongToBeat</a>, 
                        <a class="hover:underline" :href="this.gameChosen['ignUrl']" target="_blank">IGN</a>, 
                        <a data-hs-overlay="#hs-gameprops-modal" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>,
                      </span>
                      <span v-else class="text-xs mt-1">
                        <a class="hover:underline" :href="metacriticUrl" target="_blank">Metacritic</a>, 
                        <a class="hover:underline" :href="howLongToBeatUrl" target="_blank">HowLongToBeat</a>, 
                        <a class="hover:underline" :href="this.gameChosen['ignUrl']" target="_blank">IGN</a>, 
                        <a data-hs-overlay="#hs-gameprops-modal" class="cursor-pointer hover:underline" target="_blank">Game Properties</a>,
                        <a class="hover:underline" :href="this.gameChosen['microsoftStoreUrl']" target="_blank">
                          Save to <font-awesome-icon 
                            class="text-white px-1 hover:text-green-300 cursor-pointer" 
                            :icon="['fab', 'xbox']" 
                          />
                        </a> |
                        <a class="hover:underline" :href="this.gameChosen['youtubeReviewUrl']" target="_blank">Review</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeTrailerUrl']" target="_blank">Trailer</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeWalkthroughUrl']" target="_blank">Walkthrough</a>
                      </span>
                    </div>
                    <div class="flex justify-start text-white text-left pb-2">
                      <span v-if="isLargeScreen">
                        <a class="hover:underline" :href="this.gameChosen['microsoftStoreUrl']" target="_blank">
                          Save to <font-awesome-icon 
                            class="text-white px-1 hover:text-green-300 cursor-pointer" 
                            :icon="['fab', 'xbox']" 
                          />
                        </a> |
                        <a class="hover:underline" :href="this.gameChosen['youtubeReviewUrl']" target="_blank">Review Video</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeTrailerUrl']" target="_blank">Trailer Video</a>,
                        <a class="hover:underline" :href="this.gameChosen['youtubeWalkthroughUrl']" target="_blank">Walkthrough Video</a>
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <div class="my-3 text-xl text-left font-bold text-white h-12">
                      What's One of Your Favorite Games on the Game Pass?
                    </div>
                  </div>
                </div>
                <div v-if="!this.gameChosen" class="text-center ml-10 my-20">
                  <div class="flex flex-col md:flex-row items-start">
                    <div class="text-left pl-2">
                      <div><b>Find Games</b></div>
                      <div class="flex rounded-lg shadow-sm mt-2">
                          <input type="text" id="hs-search-box-with-loading-4" v-model="gameSearched" name="hs-search-box-with-loading-4" class="py-1 px-2 block w-full shadow-sm rounded-s-lg text-sm focus:z-10 focus:border-green-500 disabled:opacity-50 disabled:pointer-events-none bg-slate-700 border-gray-700 text-gray-100 focus:ring-gray-600" placeholder="e.g. Starfield">
                          <button @click="findGame(gameSearched)" class="px-2 inline-flex justify-center items-center text-sm font-semibold rounded-e-md border border-transparent bg-primary text-white hover:bg-green-700 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600">
                              <span v-if="searchLoading" class="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                  <span class="sr-only">Loading...</span>
                              </span>
                              Find
                          </button>
                      </div>
                      <div class="mt-8">
                        <div class="flex flex-row gap-2 items-start">
                          <button class="bg-transparent text-white px-1 mx-1 my-1 border border-white rounded flex items-center flex-shrink-0" style="max-height: 40px; overflow: hidden;">
                            <font-awesome-icon v-if="friendDataLoading" class="text-white" icon="fa-solid fa-hourglass" />
                            <font-awesome-icon v-else-if="gameFound" class="text-primary px-1" icon="fa-solid fa-check" />
                            <font-awesome-icon v-else-if="!gameFound" class="text-red-500 px-1" icon="fa-solid fa-x" />
                            <span class="ml-2">Game Found</span>
                          </button>
                          <div v-if="gameSearchedPreciseName" class="mx-1 mt-1"><b>{{ gameSearchedPreciseName }}</b></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p v-if="this.gameChosen && this.gameChosen['description']" class="h-28 mt-1 pr-2 text-gray-400 overflow-auto text-left scrollbar-thin scrollbar-thumb-gray scrollbar-track-dark">
                    <span class="text-white">Released in {{ this.gameChosen['originalReleaseYear'] }} - </span>
                    <span>{{ this.gameChosen['description'] }}</span>
                  </p>
                </div>
                <div>
                  <div class="flex justify-between items-start my-4" v-if="isLargeScreen">  
                  <div class="flex flex-col space-y-2">
                    <button v-if="this.gameChosen" @click="this.addGameInterest('wishlist')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 text-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gift" />&nbsp;&nbsp;Interested
                    </button>
                    <button v-if="this.gameChosen" @click="this.addGameInterest('uninterested')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-trash" />Uninterested&nbsp;
                    </button>
                  </div>

                  <div class="flex flex-col space-y-2 items-center">
                    <button v-if="!suggestGameClickedForTheFirstTime"
                            type="button" 
                            @click="pickRandomGamePassGame" 
                            :disabled="choosingGameInProcess || !gameFound" 
                            :class="['my-3 px-4 mx-2 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-primary text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-1 sm:p-3 ring-offset-gray-800', 
                                    { 'bg-gray-400 cursor-not-allowed opacity-60': choosingGameInProcess || !gameFound }]"
                    >
                      {{ !choosingGameInProcess ? 'Suggest Similar Games' : 'Suggesting...................' }}
                    </button>
                    <button v-if="suggestGameClickedForTheFirstTime"
                            type="button" 
                            @click="pickRandomGamePassGame" 
                            :disabled="choosingGameInProcess || !gameFound" 
                            :class="['my-3 px-4 mx-2 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-primary text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm p-1 sm:p-3 ring-offset-gray-800', 
                                    { 'bg-gray-400 cursor-not-allowed opacity-60': choosingGameInProcess || !gameFound }]"
                    >
                      {{ !choosingGameInProcess ? 'Suggest Another' : 'Suggesting.........' }}
                    </button>
                    <button v-if="this.gameChosen" @click="this.addGameInterest('currently playing')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      <font-awesome-icon class="text-white px-1" icon="fa-solid fa-gamepad" />Playing&nbsp;
                    </button>
                  </div>

                  <div class="flex flex-col space-y-2">
                    <button v-if="this.gameChosen" @click="this.addGameInterest('played')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 text-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Played&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-hourglass-half" />
                    </button>
                    <button v-if="this.gameChosen" @click="this.addGameInterest('completed')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 text-right gap-2 rounded-md border border-gray-400 font-semibold text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                      Completed&nbsp;&nbsp;<font-awesome-icon class="text-white px-1" icon="fa-solid fa-check-to-slot" />
                    </button>
                  </div>
                </div>
                <div class="my-2" v-else>

                  <div class="flex flex-row justify-center mb-2">
                    <div class="flex flex-col">
                      <button v-if="this.gameChosen" @click="this.addGameInterest('wishlist')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Interested
                      </button>
                    </div>
                    <button v-if="!suggestGameClickedForTheFirstTime"
                            type="button" 
                            @click="pickRandomGamePassGame" 
                            :disabled="choosingGameInProcess || !gameFound" 
                            :class="['mx-1 px-2 p-1 sm:p-4 py-2 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs ring-offset-gray-800', 
                                    { 'bg-gray-400 cursor-not-allowed opacity-60': choosingGameInProcess || !gameFound }]"
                    >
                      {{ !choosingGameInProcess ? 'Suggest Game' : 'Suggest Game' }}
                    </button>
                    <button v-if="suggestGameClickedForTheFirstTime"
                            type="button" 
                            @click="pickRandomGamePassGame" 
                            :disabled="choosingGameInProcess || !gameFound" 
                            :class="['mx-1 px-2 p-1 sm:p-4 py-2 inline-flex justify-center items-center rounded-md border border-transparent font-semibold bg-primary text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs ring-offset-gray-800', 
                                    { 'bg-gray-400 cursor-not-allowed opacity-60': choosingGameInProcess || !gameFound }]"
                    >
                      {{ !choosingGameInProcess ? 'Suggest Another' : 'Suggest Another' }}
                    </button>
                    <div class="flex flex-col">
                      <button v-if="this.gameChosen" @click="this.addGameInterest('uninterested')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Uninterested
                      </button>
                    </div>
                  </div>

                  <div class="flex flex-row justify-center">
                    <div class="flex flex-col">
                      <button v-if="this.gameChosen" @click="this.addGameInterest('currently playing')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-4 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Playing
                      </button>
                    </div>
                    <div class="flex flex-col">
                      <button v-if="this.gameChosen" @click="this.addGameInterest('played')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Played
                      </button>
                    </div>
                    <div class="flex flex-col">
                      <button v-if="this.gameChosen" @click="this.addGameInterest('completed')" type="button" :disabled="choosingGameInProcess || !gameChosen" class="px-2 mx-2 mb-2 inline-flex justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
                        Completed
                      </button>
                    </div>
                  </div>
                </div>
                  <!-- <div v-if="authenticated && !premiumMember && userQuota" class="text-xs pb-1">
                    <span v-if="aiRecommendationsUsed">
                      You have <b>{{ userQuota['ai_recommendation_quota'] >= 0 ? userQuota['ai_recommendation_quota'] : 0 }}</b> AI functionality uses left this week, <router-link to="/premium-pricing" class="text-primary hover:underline">Join Premium</router-link> to get unlimited
                    </span>
                    <span v-else-if="addGameInterestSelected">
                      You have <b>{{ userQuota['game_addition_quota'] }}</b> game additions left this week, <router-link to="/premium-pricing" class="text-primary hover:underline">Join Premium</router-link> to get unlimited
                    </span>
                  </div> -->
                  <button ref="triggerNoGamesAvailableModal" type="button" class="hidden" data-hs-overlay="#hs-no-games-available-modal"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <!-- ========== END MAIN CONTENT ========== -->

      <!-- NOTIFICATION (STARTS) -->
      <div v-if="notificationPresent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-xs border rounded-xl shadow-lg bg-gray-800 border-primary-nowhite z-50" role="alert">
        <div class="p-4 relative">
          <button @click="notificationPresent = false" class="absolute top-2 right-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div class="mb-2 text-center flex justify-center items-center">
            <svg class="flex-shrink-0 h-4 w-4 text-primary mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
          </div>
          <div class="ms-3">
            <p class="text-lg text-left text-gray-200 pl-2">
                {{ notification }}
            </p>
            <div v-if="isPlayingOrPlayedGame && !notificationLinkPresent" class="mt-3">
              <hr />
              <div class="mt-2 text-lg font-bold">What would you rate this game?</div>
              <div class="flex justify-center align-center mt-2">
                <span class="rate">
                  <i @click="rateGame(gameChosen['title'], 1)">★</i>
                  <i @click="rateGame(gameChosen['title'], 2)">★</i>
                  <i @click="rateGame(gameChosen['title'], 3)">★</i>
                  <i @click="rateGame(gameChosen['title'], 4)">★</i>
                  <i @click="rateGame(gameChosen['title'], 5)">★</i>
                </span>
              </div>
            </div>
            <div v-if="notificationLinkPresent" class="text-lg text-center mt-2">
                <a href="/recommendations-from-favorite-games" class="hover:underline">Search for a Different Game</a>
            </div>
          </div>
        </div>
      </div>
      <!-- NOTIFICATION (ENDS) -->
      
      <!-- POP-UP MODAL FOR GAME PROPERTIES (STARTS) -->
      <div id="hs-gameprops-modal" class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex justify-center items-center inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm">
        <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3">
          <div class="flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
            <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
              <h3 class="font-bold text-white">
                Game Properties
              </h3>
              <button type="button" class="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-sm focus:ring-gray-700 focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal">
                <span class="sr-only">Close</span>
                <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                </svg>
              </button>
            </div>
            <div class="p-4 overflow-y-auto">
              <p class="mt-1 text-gray-400">
                {{ (this.gameChosen && this.gameChosen['gameAttributes']) ? this.gameChosen['gameAttributes'] : 'None at this time.' }}
              </p>
            </div>
            <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-gray-700">
              <button type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-slate-900 hover:bg-slate-800 border-gray-700 text-gray-400 hover:text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-gameprops-modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- POP-UP MODAL FOR GAME PROPERTIES (ENDS) -->

      <!-- WE NEED MORE INFO ERROR MODAL (STARTS) -->
      <div id="hs-no-games-available-modal" class="hs-overlay hidden w-full h-full fixed top-0 left-0 z-[60] overflow-x-hidden overflow-y-auto flex justify-center items-center inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm">
          <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-lg sm:w-full m-3">
              <div class="flex flex-col border shadow-sm rounded-xl bg-gray-800 border-gray-700 shadow-slate-700/[.7]">
              <div class="flex justify-between items-center py-3 px-4 border-b border-gray-700">
                  <h3 class="font-bold text-white text-left">
                  No games available for you at the time
                  </h3>
              </div>
              <div class="m-4 text-left">
                  Either change the favorite game that you searched, or contact support. Hint: Check your spelling of the game.
              </div>
              <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-gray-700">
                  <button type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium shadow-sm align-middle focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-all text-sm bg-slate-900 hover:bg-slate-800 border-gray-700 text-gray-400 hover:text-white focus:ring-offset-gray-800" data-hs-overlay="#hs-no-games-available-modal">
                  Close
                  </button>
              </div>
              </div>
          </div>
      </div>
      <!-- WE NEED MORE INFO ERROR MODAL (ENDS) -->
                  
      <FooterComponent />
    </div>
  </body>


</template>

<script>
import axios from 'axios';

import { useStore } from 'vuex';

import FooterComponent from '../components/Layout/FooterComponent.vue';
import HeaderComponent from '../components/Layout/HeaderComponent.vue';

// Axios configurations
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const store = useStore();

export default {
  name: 'GamePickerPage',
  data: function() {
    return {
      loading: false,
      friendDataLoading: false,
      syncLoading: false,
      gameChosen: null,
      allGames: [],
      allGamesRecommendedAI: [],
      filteredGames: [],
      gameRecommendations: [],
      choosingGameInProcess: false,
      genresSelected: ['All'],
      propertiesSelected: ['All'],
      multiplayerCategoriesSelected: ['All'],
      allPossibleGenres: [],
      allPossibleProperties: [],
      allMultiplayerCategories: {
          'All': [
            'Xbl Local Multi Player',
            'Xbl Online Multi Player',
            'Xbl Local Coop',
            'Xbl Online Coop',
            'Xbl Cross Platform Multi Player',
            'Xbl Cross Platform Coop',
            'Shared Split Screen',
            'Coop Support Local',
            'Coop Support Online',
            'Local Multiplayer',
            'Online Multiplayer With Gold'
          ],
          'Local Coop': ['Xbl Local Coop', 'Coop Support Local'],
          'Online Coop': ['Xbl Online Coop', 'Coop Support Online'],
          'Local Multiplayer': ['Xbl Local Multi Player', 'Local Multiplayer'],
          'Online Multiplayer': ['Xbl Online Multi Player', 'Online Multiplayer With Gold'],
          'Cross Platform': ['Xbl Cross Platform Multi Player', 'Xbl Cross Platform Coop'],
          'Shared Split Screen': ['Shared Split Screen']
      },
      platformAll: true,
      platformXbox: false,
      platformPc: false,
      typeAll: true,
      typeNewReleases: false,
      typeLeavingSoon: false,
      typeOther: false,
      gameplayLenAll: true,
      gameplayLenZeroToFiveHours: false,
      gameplayLenFiveToTwentyHours: false,
      gameplayLenTwentyToFiftyHours: false,
      gameplayLenOverFiftyHours: false,
      isPlayingOrPlayedGame: false,
      numGamesToChooseFrom: 0,
      fewGamesToChooseFrom: false,
      gamePickedCounter: 0,
      gamePickedCounterThreshold: 1000000,
      currentGameImageShown: 0,
      authenticated: false,
      noGamesAvailableModal: false,
      user_id: null,
      notificationPresent: false,
      notification: 'Game has been added to your list successfully',
      notificationLinkPresent: false,
      notificationTimeoutId: null,
      isLargeScreen: false,
      userQuota: null,
      addGameInterestSelected: false,
      aiRecommendationsUsed: false,
      suggestGameClickedForTheFirstTime: false,
      gameSearched: null,
      gameFound: false,
      gameSearchedPreciseName: null,
    }
  },
  components: {
    HeaderComponent,
    FooterComponent,
  },
  beforeMount: async function() {
    this.loading = true;

    // First we check screen size
    await this.checkScreenSize();

    // Attempting to get user information in case they are logged in
    await this.getUserLoginStatus();

    // Next we get check and reset the user's game picking quota
    // await this.checkAndResetSubscriptionQuota();

    // We next get the user's leftover quota
    await this.getUserSubscriptionQuota();

    this.loading = false;
  },
  watch: {
    multiplayerCategoriesSelected: {
      handler() {

        // Making a deep copy of the recommended games
        this.filteredGames = JSON.parse(JSON.stringify(this.allGamesRecommendedAI));
        // Further filter the games based on the allMultiplayerCategories selected
        this.filteredGames = this.filteredGames.filter(game => {
          if (this.multiplayerCategoriesSelected.includes('All')) {
            return true;
          } else {
            for (const multiCategory of this.multiplayerCategoriesSelected) {
              // Get the actual categories from the allMultiplayerCategories dictionary
              const categories = this.allMultiplayerCategories[multiCategory] || [];
              
              for (const category of categories) {
                if (game['game_attributes'].includes(category)) {
                  return true;
                }
              }
            }
            return false;
          }
        });

        // Randomizing games so the randomized picking order is achieved with no duplicates
        this.pseudoRandomizeGames();
      },
      deep: true // Ensure deep watching
    }
  },
  computed: {
    metacriticUrl() {
      return `https://www.metacritic.com/search/${this.gameChosen['searchableTitle']}/`;
    },
    howLongToBeatUrl() {
      return `https://howlongtobeat.com/?q=${this.gameChosen['searchableTitle']}`;
    }
  },
  methods: {
    prepGamesForChoosing: async function() {
      // Setting up all the games from our dataset
      const allGames = require('../assets/data/games-info-all.json');
      this.allGames = allGames['data'];

      // Use the game recommendations which is an array of game titles to filter allGames on title
      this.allGamesRecommendedAI = this.allGames.filter(game => 
          this.gameRecommendations.includes(game['title'])
      );


      // Making a deep copy of the recommended games
      this.filteredGames = JSON.parse(JSON.stringify(this.allGamesRecommendedAI));

      // Further filter the games based on the allMultiplayerCategories selected
      this.filteredGames = this.filteredGames.filter(game => {
        if (this.multiplayerCategoriesSelected.includes('All')) {
          return true;
        } else {
          for (const multiCategory of this.multiplayerCategoriesSelected) {
            // Get the actual categories from the allMultiplayerCategories dictionary
            const categories = this.allMultiplayerCategories[multiCategory] || [];
            
            for (const category of categories) {
              if (game['game_attributes'].includes(category)) {
                return true;
              }
            }
          }
          return false;
        }
      });


      // this.filteredGames = allGames['data'];
      // Randomizing games so the randomized picking order is achieved with no duplicates
      await this.pseudoRandomizeGames();

      // Extracting our unique genres from our dataset
      const allGenres = this.allGames.reduce(
        (acc, game) => [...acc, ...game['ign_all_possible_genres']], []
      );
      const uniqueGenres = [...new Set(allGenres)];

      const initialGenresToPickOrder = [
        "All",
        "Shooter",
        "Action",
        "Adventure",
        "RPG",
        "Simulation",
        "Battle Royale",
        "Sports",
        "Racing",
        "Strategy",
        "Puzzle",
        "Fighting"
      ]

      const restOfGenres = uniqueGenres.filter(genre => !initialGenresToPickOrder.includes(genre));

      // Sort the restOfGenres in alphabetical order
      restOfGenres.sort((a, b) => a.localeCompare(b));

      const properlyOrderedGenresList = initialGenresToPickOrder.concat(restOfGenres);

      this.allPossibleGenres = properlyOrderedGenresList;

      // Extracting our unique game properties from our dataset
      const allGameProperties = this.allGames.reduce(
        (acc, game) => [...acc, ...game['game_attributes']], []
      );
      const uniqueGameProperties = [...new Set(allGameProperties)];

      const initialGamePropertiesToPickOrder = [
          "All", // All games are included in the list.
          "Cloud Gaming Enabled",
          "Single Player", // The ability to play games alone is essential for many.
          "Xbl Local Multi Player", // Local multiplayer can provide fun social experiences.
          "Xbl Online Multi Player", // Online multiplayer opens up the opportunity to play with friends or people worldwide.
          "Xbl Local Coop", // Co-op gameplay can add to the enjoyment for many players.
          "Xbl Online Coop", // Like local coop, but allows play with people from anywhere.
          "Xbl Cross Platform Multi Player", // Allows gamers to play with others regardless of platform.
          "Xbl Cross Platform Coop", // Like cross platform multiplayer, but in a cooperative setting.
          "Shared Split Screen", // Shared split-screen is a beloved feature of many multiplayer console games.
          "Capability4k", // High resolution graphics are often important to gamers.
          "Console Cross Gen", // Ability to play across different console generations is important for many.
          "Xbox Live", // Xbox Live allows for multiplayer gaming and other online services.
          "Xbl Achievements", // Achievements can add an extra level of fun and challenge.
          "Game Streaming", // Allows gamers to share their gameplay with others online.
          "Broadcast Support", // Allows gamers to broadcast their gameplay live.
          "Console Keyboard Mouse", // Some gamers prefer keyboard/mouse over a controller.
          "Pc Game Pad" // Some PC gamers prefer using a game pad.
      ];

      const restOfGameProperties = uniqueGameProperties.filter(gameProperty => !initialGamePropertiesToPickOrder.includes(gameProperty));

      // Sort restOfGameProperties in alphabetical order
      restOfGameProperties.sort((a, b) => a.localeCompare(b));

      const properlyOrderedGamePropertiesList = initialGamePropertiesToPickOrder.concat(restOfGameProperties);

      this.allPossibleProperties = properlyOrderedGamePropertiesList;
    },
    generateGameDetailSlug: function(gameTitle) {
      return gameTitle
        .toLowerCase()                       // Convert to lowercase
        .replace(/[^a-z0-9\s-]/g, '')        // Remove invalid characters
        .trim()                              // Remove leading/trailing spaces
        .replace(/\s+/g, '-')                // Replace spaces with hyphens
        .replace(/-+/g, '-')                 // Remove duplicate hyphens
    },
    viewGameDetailsPage: async function(gameId, gameTitle) {

      const gameDetailSlug = await this.generateGameDetailSlug(gameTitle);
      this.$router.push(
        { 
          name: 'GameDetailsPage', 
          params: { gameId: gameId, gameTitle: gameDetailSlug} 
        }
      );
    },
    // checkAndResetSubscriptionQuota: async function() {
    //   // Checking and resetting the user's game picking quota only applies
    //   // if the user is authenticated
    //   if (!this.authenticated) {
    //     return;
    //   }

    //   // Initialize a time-zone aware date
    //   const userTimezoneDatetime = new Date().toISOString();

    //   const inputData = {
    //     user_tz_aware_current_datetime: userTimezoneDatetime
    //   };

    //   // Make the API call
    //   try {
    //     await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/check-and-reset-subscription-quota/`, inputData,
    //       {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             // 'X-CSRFToken': csrfToken
    //         },
    //         withCredentials: true,
    //       })
    //   } catch (error) {
    //     console.error('Could not check and reset the subscription quota for the user.', error);
    //   }
    // },
    getUserSubscriptionQuota: async function() {
      // Getting the user's subscription quota only applies
      // if the user is authenticated
      if (!this.authenticated) {
        return;
      }

      // Make the API call
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-user-subscription-quota/`,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

        if (response.data) {
          this.userQuota = response.data;
          // this.premiumMember = (this.userQuota['subscription_type'] !== 'free');
        }

      } catch (error) {
        console.error('Could not retrieve the subscription quota for the user.', error);
      }
    },
    depleteQuota: async function (attemptType) {
      const inputData = {
        attempt_type: attemptType
      };

      // Make the API call
      try {
        await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/quota-deplete/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

      } catch (error) {
        console.error('Could not deplete the quota for the user.', error);
      }
    },
    copyUrlToClipboard: function () {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied - share it with your friends');
    },
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > 768; // Example breakpoint
    },
    clearExistingNotification() {
      if (this.notificationTimeoutId) {
        clearTimeout(this.notificationTimeoutId);
        this.notificationTimeoutId = null;
        this.notificationPresent = false;
        this.notificationLinkPresent = false;
        this.isPlayingOrPlayedGame = false;
      }
    },
    getUserLoginStatus: async function () {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'  // Necessary for passing of login cookies from backend
        })

        if (response.status === 403) {
          await store.dispatch('setAuth', false);
          await this.navigateToLoginPage();
        }

        const content = await response.json();

        if (content.detail === 'Unauthenticated!') {
          this.authenticated = false;
          this.navigateToLoginPage();
        } else {
          this.authenticated = true;
          this.user_id = content.id;
        }
      } catch (e) {
        this.authenticated = false;
        this.navigateToLoginPage();
      }

    },
    isRecentlyReleased: function (game) {
      if (!game.releaseOnGamePassDate) return false;
      const releaseDate = new Date(game.releaseOnGamePassDate);
      const today = new Date();
      const timeDifference = today - releaseDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      return daysDifference <= 7;
    },
    getGameRecommendations: async function () {
        if (!this.authenticated || !this.gameSearchedPreciseName) {
            console.log('You must be logged in and add a friend who has an account to get game recommendations.');
            return;
        }

        // Define the payload data
        const inputData = {
          game_searched: this.gameSearchedPreciseName
        };

        try {
            
            // Make the API call using await
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/get-game-recommendations-from-favorite-games/`, inputData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                });

            // Handle success
            this.gameRecommendations = response.data;

        } catch (error) {
            // Handle error
            console.error('We could not get game recommendations for you and your friend(s) at this time', error);
        }
    },
    addGameInterest: function(interest_status) {
      // First we clear any present notifications
      this.clearExistingNotification();

      if (!this.authenticated) {
        console.log('You must be logged in to add a game to your list of interests.');
        return;
      }

      // If the user has no quota left, we notify them and return
      // if (this.authenticated && !this.premiumMember && this.userQuota['game_addition_quota'] === 0) {
      //   this.notification = 'You have no game additions left this week. Please upgrade to premium to get unlimited game additions.';
      //   this.notificationPresent = true;

      //   this.notificationTimeoutId = setTimeout(() => {
      //       this.notificationPresent = false;
      //   }, 3000);

      //   this.aiRecommendationsUsed = false;
      //   this.addGameInterestSelected = true;

      //   return;
      // }

      // Checking if the user is adding a playing, played, or completed game
      // This is to see if the user needs to rate the game or not
      if (
        interest_status === 'currently playing' || 
        interest_status === 'played'|| 
        interest_status === 'completed') {
        this.isPlayingOrPlayedGame = true;
      }

      // For notifications where the user needs to rate the game, we increase
      // the timeout
      const timeoutDurationSeconds = this.isPlayingOrPlayedGame ? 6 : 2;

      // Define the payload data
      const inputData = {
        user_id: this.user_id,
        game_title: this.gameChosen['title'],
        status: interest_status
      };

      // Make the API call
      axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/game-interest/`, inputData,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
        })
        .then(response => {
          // Handle success, maybe provide feedback to the user or update the UI
          console.log('Game has been added to your list successfully', response)

          if (interest_status === 'uninterested') {
            this.notification = 'Game has been removed from your preferences and lists successfully';
          } else if (interest_status === 'wishlist') {
            this.notification = 'Game has been added to your "Interested" list successfully';
          } else if (interest_status === 'currently playing') {
            this.notification = 'Game has been added to your "Currently Playing" list successfully';
          } else if (interest_status === 'played') {
            this.notification = 'Game has been added to your "Played" list successfully';
          } else if (interest_status === 'completed') {
            this.notification = 'Game has been added to your "Completed" list successfully';
          }

          // Notifying user that the game has been added to their list
          this.notificationPresent = true;

          this.notificationTimeoutId = setTimeout(() => {
              this.notificationPresent = false;
              this.isPlayingOrPlayedGame = false;
          }, timeoutDurationSeconds * 1000);

          // Depleting the user's quota
          this.depleteQuota('game_addition_quota');

          this.aiRecommendationsUsed = false;
          this.addGameInterestSelected = true;
        })
        .catch(error => {
          // Handle error, maybe show an error message to the user
          console.error('There was an error!', error);
        });
    },
    nextGameImageShown() {
      if (this.currentGameImageShown < this.gameChosen['images'].length - 1) {
        this.currentGameImageShown++;
      } else {
        this.currentGameImageShown = 0;
      }
    },
    prevGameImageShown() {
      if (this.currentGameImageShown > 0) {
        this.currentGameImageShown--;
      } else {
        this.currentGameImageShown = this.gameChosen['images'].length - 1;
      }
    },
    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },
    uncheckGameplayLenAll(gameplayLen) {
      if (this[gameplayLen]) {
        this.gameplayLenAll = false;
      }
    },
    uncheckPlatformAll(platform) {
      if (this[platform]) {
        this.platformAll = false;
      }
    },
    uncheckTypeAll(type) {
      if (this[type]) {
        this.typeAll = false;
      }
    },
    toggleGenre(genre) {
      // If "All" is selected, deselect everything else
      if (genre === 'All') {
        this.genresSelected = this.genresSelected.includes('All') ? [] : ['All'];
      } else {
        // If anything else is selected, deselect "All"
        if (this.genresSelected.includes('All')) {
          this.genresSelected = [];
        }
        if (this.genresSelected.includes(genre)) {
          this.genresSelected = this.genresSelected.filter(g => g !== genre);
        } else {
          this.genresSelected.push(genre);
        }
      }
    },
    toggleMultiplayerCategory(property) {
      // If "All" is selected, deselect everything else
      if (property === 'All') {
        this.multiplayerCategoriesSelected = this.multiplayerCategoriesSelected.includes('All') ? [] : ['All'];
      } else {
        // If anything else is selected, deselect "All"
        if (this.multiplayerCategoriesSelected.includes('All')) {
          this.multiplayerCategoriesSelected = [];
        }
        if (this.multiplayerCategoriesSelected.includes(property)) {
          this.multiplayerCategoriesSelected = this.multiplayerCategoriesSelected.filter(g => g !== property);
        } else {
          this.multiplayerCategoriesSelected.push(property);
        }
      }
    },
    toggleProperty(property) {
      // If "All" is selected, deselect everything else
      if (property === 'All') {
        this.propertiesSelected = this.propertiesSelected.includes('All') ? [] : ['All'];
      } else {
        // If anything else is selected, deselect "All"
        if (this.propertiesSelected.includes('All')) {
          this.propertiesSelected = [];
        }
        if (this.propertiesSelected.includes(property)) {
          this.propertiesSelected = this.propertiesSelected.filter(g => g !== property);
        } else {
          this.propertiesSelected.push(property);
        }
      }
    },
    pseudoRandomizeGames: async function () {
      // Randomize the filtered games so that the picking order is different
      // each time we apply the picking preferences
      if (this.filteredGames.length > 0) {
        this.filteredGames = this.filteredGames.sort(() => Math.random() - 0.5);
      }
      this.gamePickedCounterThreshold = this.filteredGames.length - 1;
      
      // Resetting the game picked counter to 0 as we start fresh w/ new search
      this.gamePickedCounter = 0;
    },
    makeGameNameSearchable: function (gameName) {
      if (!gameName) {
        return '';
      }

      const elements = [
          "™",
          "®",
          "(Game Preview)",
          "(Xbox Series X|S & PC)",
          "(Xbox Series X|S)",
          "(Xbox One Edition)",
          "(Xbox One)",
          "Game Preview",
          "Xbox Series X|S & PC",
          "Xbox Series X|S",
          "Xbox One Edition",
          "Xbox One"
      ];

      let result = gameName;
      for (let element of elements) {
          const regex = new RegExp(this.escapeRegExp(element), 'g');
          result = result.replace(regex, "");
      }
      return result.trim();
    },
    navigateToAboutPage: function() {
      this.$router.push('/about');
    },
    navigateToLoginPage: function() {
      this.$router.push('/login');
    },
    navigateToMyGamesPage: function() {
      this.$router.push('/my-games');
    },
    navigateToGamePickerPage: function() {
      this.$router.push('/');
    },
    findGame: async function(gameSearched) {
      // First we clear any notification
      this.clearExistingNotification();
      this.friendDataLoading = true;

      const inputData = {
        game_searched: gameSearched
      };

      // Make the API call
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/find-game-fuzzy-match/`, inputData,
        {
          headers: {
              'Content-Type': 'application/json',
              // 'X-CSRFToken': csrfToken
          },
          withCredentials: true,
        })

        if (response.status === 200) {
          const data = response.data;

          // Parse the return_obj
          if (data.game_found) {
            this.gameSearchedPreciseName = data.game_found;
            this.gameFound = true;
          }

        }

      } catch (error) {
        if (error.response.status === 404) {
          // Notify user that the friend's gamertag was not found
          this.gameFound = false;

          this.notification = 'Game not found. Try a different name, or search for your game under "Search Games" and find the exact title.';
          this.notificationPresent = true;
          
          this.notificationTimeoutId = setTimeout(() => {
              this.notificationPresent = false;
          }, 6000);

          // Make sure that the game found is set to false and the searched game is set to null
          this.gameFound = false;
          this.gameSearchedPreciseName = null;

          // Scroll to the top to see the notification better on mobile screens
          window.scrollTo(0, 0);
        }

        console.error('Could not find the friend user by gamertag.', error);
      }

      this.friendDataLoading = false;
    },
    pickRandomGamePassGame: async function() {
      // First we clear any present notifications
      this.clearExistingNotification();

      // Resetting the image index to be 0, so we always see the first photo 
      // for the game
      this.currentGameImageShown = 0;
      
      if (!this.authenticated) {
        console.log('You must be logged in to get AI recommendations.');
        return;
      }

      // The first time the user clicks the suggest game button, we get the
      // Game recommendations if there is any quota left
      if (!this.suggestGameClickedForTheFirstTime) {
        this.choosingGameInProcess = true;

        this.suggestGameClickedForTheFirstTime = true;

        if (this.authenticated) {

            // Depleting the user's quota
            await this.depleteQuota('ai_recommendations_from_favorite_games_quota')

            // Otherwise, we get the game recommendations
            await this.getGameRecommendations();
            await this.prepGamesForChoosing();

            this.addGameInterestSelected = false;
            this.aiRecommendationsUsed = true;
        }

      }

      if (this.filteredGames.length === 0) {
        this.noGamesAvailableModal = true;
        const elem = this.$refs.triggerNoGamesAvailableModal;
        elem.click();
        this.choosingGameInProcess = false;
        return;
      } else {

        // We have some games to pick from so we proceed
        this.choosingGameInProcess = true;

        // Sleeping for half a second to allow the browser to update
        await new Promise(resolve => setTimeout(resolve, 500));

        try {
          // TODO: To reinstate code once we implement the updated database
          // const response = await fetch(
          //   `${process.env.VUE_APP_BACKEND_API_ROUTE}/pick-game`, 
          //   {
          //     method: 'GET',
          //     mode: 'cors',
          //     headers: {'Content-Type': 'application/json'}
          //   }
          // );

          // this.gameChosen = await response.json();
          // console.log('game_chosen: ', this.gameChosen)

          // TODO:
          // Eventually we want to move this to a GraphQL Database and have
          // the backend hook-into that and we serve this via request to backend

          let gamePicked = this.filteredGames[this.gamePickedCounter];
          // Incrementing by one so that next time user hits pick game, we pick the next game
          this.gamePickedCounter += 1;

          // // If we reach the threshold, free members get a notification
          // if (!this.premiumMember && this.gamePickedCounter > this.gamePickedCounterThreshold) {
          //   this.notification = 'As a free user you only get 5 recommendations at one time. Come back later for more recommendations or Join Premium for Unlimited recommendations.';

          //   // Notifying user that the game has been added to their list
          //   this.notificationPresent = true;
          //   this.notificationLinkPresent = true;

          //   this.notificationTimeoutId = setTimeout(() => {
          //       this.notificationPresent = false;
          //       this.notificationLinkPresent = false;
          //   }, 10000);
          //   this.gamePickedCounter = 0;
          // }

          // If we reach the threshold, paid members get a new set of recommendations
          // if (this.premiumMember && this.gamePickedCounter > this.gamePickedCounterThreshold) {
          // If we reach the threshold, all members get a new set of recommendations
          if (this.gamePickedCounter > this.gamePickedCounterThreshold + 1) {

            // We notify the user that they should rate more games
            this.notification = 'Those are all the recommendations for now, if you want different recommendations - pick a different favorite game!';

            // Notifying user that the game has been added to their list
            this.notificationPresent = true;
            this.notificationLinkPresent = true;

            this.notificationTimeoutId = setTimeout(() => {
                this.notificationPresent = false;
                this.notificationLinkPresent = false;
            }, 6000);

            await this.getGameRecommendations();
            await this.prepGamesForChoosing();
            this.gamePickedCounter = 0;
            gamePicked = this.filteredGames[this.gamePickedCounter];
            this.gamePickedCounter += 1;
          }

          const backupMicrosoftStoreUrl = `https://www.xbox.com/en-us/games/store/${gamePicked['title']}/${gamePicked['xbox_api_id']}`
          const backupYoutubeReviewUrl = `https://www.youtube.com/results?search_query=${gamePicked['title']}+review`;
          const backupYoutubeTrailerUrl = `https://www.youtube.com/results?search_query=${gamePicked['title']}+trailer`;
          const backupYoutubeWalkthroughUrl = `https://www.youtube.com/results?search_query=${gamePicked['title']}+full+walkthrough`;

          let gameAttributes = 'None at this time.';
          if (gamePicked['game_attributes'].length > 0) {
            gameAttributes = gamePicked['game_attributes'].join(', ');
          }

          this.gameChosen = {
            'title': gamePicked['title'] ? gamePicked['title'] : 'NA',
            'searchableTitle': this.makeGameNameSearchable(gamePicked['title']),
            'description': gamePicked['short_description'] ? gamePicked['short_description'] : 'Description not available.',
            'images': gamePicked['image_uris'] ? gamePicked['image_uris'] : 'NA',
            'developerName': gamePicked['developer_name'] ? gamePicked['developer_name'] : 'NA',
            'platforms': gamePicked['platforms'] ? gamePicked['platforms'] : 'NA',
            'ignScore': gamePicked['ign_score'] ? gamePicked['ign_score'] : 'NA',
            'ignDurationHours': gamePicked['ign_duration_hours'] ? gamePicked['ign_duration_hours'] : 'NA',
            'ignAllPossibleGenres': gamePicked['ign_all_possible_genres'] ? gamePicked['ign_all_possible_genres'] : 'NA',
            'ignUrl': gamePicked['ign_url'] ? gamePicked['ign_url'] : 'https://www.ign.com/',
            'microsoftStoreUrl': gamePicked['microsoft_store_url'] ? gamePicked['microsoft_store_url'] : backupMicrosoftStoreUrl,
            'youtubeReviewUrl': gamePicked['youtube_review_url'] ? gamePicked['youtube_review_url'] : backupYoutubeReviewUrl,
            'youtubeTrailerUrl': gamePicked['youtube_trailer_url'] ? gamePicked['youtube_trailer_url'] : backupYoutubeTrailerUrl,
            'youtubeWalkthroughUrl': gamePicked['youtube_walkthrough_url'] ? gamePicked['youtube_walkthrough_url'] : backupYoutubeWalkthroughUrl,
            'originalReleaseYear': gamePicked['original_release_year'] ? gamePicked['original_release_year'] : '--',
            'releaseOnGamePassDate': gamePicked['release_on_gamepass_date'] ? gamePicked['release_on_gamepass_date'] : null,
            'leavingSoon': gamePicked['leaving_soon'],
            'leavingSoonDate': gamePicked['leaving_soon_date'] ? gamePicked['leaving_soon_date'] : 'Soon',
            'gameAttributes': gameAttributes,
            'xboxApiId': gamePicked['xbox_api_id'] ? gamePicked['xbox_api_id'] : 'NA'
          }

        } catch (error) {
          console.log('Oops, an error has occurred when picking this game.');
          console.log(error);

          this.choosingGameInProcess = false;
        }

          this.choosingGameInProcess = false;
      }
    },
    rateGame: async function(gameTitle, newRating) {

      if (newRating < 0 || newRating > 5 || isNaN(newRating)) {
        alert('Please enter a rating between 0 and 5.');
        return;
      }

      // Define the payload data
      const inputData = {
        game_title: gameTitle,
        rating: newRating
      };

      // Make the API call
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_API_ROUTE}/rate-game/`, inputData,
          {
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': csrfToken
            },
            withCredentials: true,
          })

          if (response.data) {
            await this.$store.dispatch('setGameKarmaPoints', response.data.points_balance);
          }

      } catch (error) {
        console.error('There was an error!', error);
      }

      // Wait for a brief moment (half a second) to show the user that their 
      // rating was successful
      await new Promise(r => setTimeout(r, 250));

      // We clear any present notifications, with a brief API call delay, to
      // show user on mobile screens that their rating was successful
      this.clearExistingNotification();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .ign-score-container {
    position: relative;
    text-align: center;
    color: white;
  }

  .ign-score {
    position: absolute;
    font-weight: bolder;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -45%);
  }

  /* These styles are applied by default.
    They will also override the hover
    style for all stars to the right
    of the currently hovered star. */
  .rate i, .rate i:hover ~ i {
    color: gray;
    text-shadow: none;
    transition: color 200ms,
                text-shadow 200ms;
    /* This will remove the delay when
      moving the cursor left or right
      within the set of stars. */
    transition-delay: 0;
  }

  /* This is the style that will be
    applied to all stars and then
    subsequently removed from the stars
    to the right of the one being
    hovered. */
  .rate:hover i {
    color: #fc0;
    text-shadow: #fc0 0 0 20px;
  }

  /* Make the effect apply one star at a
    time. Limiting the selector to when
    .rate is hovered removes the effect
    without delay when cursor leaves
    the .rate area. */
  .rate:hover i:nth-child(2) {
    transition-delay: 30ms;
  }

  .rate:hover i:nth-child(3) {
    transition-delay: 60ms;
  }

  .rate:hover i:nth-child(4) {
    transition-delay: 90ms;
  }

  .rate:hover i:nth-child(5) {
    transition-delay: 120ms;
  }

  /* Miscellaneous styles. */
  .rate i {
    cursor: pointer;
    font-style: normal;
  }


</style>
