<template>

  <body class="bg-slate-900 min-h-full">

    <div class="max-w-[50rem] flex flex-col mx-auto w-full min-h-screen">

      <HeaderComponent />

      <main v-if="!reccTypeChosen">
        <div class="text-xl font-bold mb-5">Game Recommendations</div>
        <div class="mx-5 mb-10 text-left">
          Get personalized and AI-based game recommendations for you and your 
          friends based on your gaming preferences, play history, and more.
          <br>
          <br>
          For the best recommendations, <b>rate games</b> in your game dashboard, 
          <b>add your Xbox GamerTag</b>, and <b>sync your Xbox Games</b>. The same applies 
          to the friends you want to find games with. Aim for
          <font-awesome-icon class="text-primary px-1" icon="fa-solid fa-check" />
          instead of
          <font-awesome-icon class="text-red-500 px-1" icon="fa-solid fa-x" />.
        </div>
        <div class="inline-flex w-full">
          <button v-if="isLargeScreen" @click="browseToReccsSelf()" type="button" class="flex-1 h-24 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-user" />
            <span class="pt-2">Your Game Recs</span>
          </button>
          <button v-else @click="browseToReccsSelf()" type="button" class="flex-1 h-32 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-user" />
            <span class="pt-2">Your Game Recs</span>
          </button>
          <button v-if="isLargeScreen" @click="browseToReccsForFriends()" type="button" class="flex-1 h-24 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-users" />
            <span class="pt-1">Game Recs for You and Your Friends</span>
          </button>
          <button v-else @click="browseToReccsForFriends()" type="button" class="flex-1 h-32 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-users" />
            <span>Game Recs for You and Your Friends</span>
          </button>
          <button v-if="isLargeScreen" @click="browseToReccsFromFavoriteGames()" type="button" class="flex-1 h-24 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-heart" />
            <span class="pt-1">Similar Games to Your Favorite Game</span>
          </button>
          <button v-else @click="browseToReccsFromFavoriteGames()" type="button" class="flex-1 h-32 mx-5 px-2 flex flex-col justify-center items-center gap-2 rounded-md border border-gray-400 font-semibold text-white hover:text-green-500 hover:border-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all text-xs p-1 sm:p-2 ring-offset-gray-800">
            <font-awesome-icon class="text-inherit text-lg" icon="fa-solid fa-heart" />
            <span>Similar Games to Your Favorite Game</span>
          </button>
        </div>
      </main>

      <FooterComponent />
    </div>
  </body>


</template>

<script>

import FooterComponent from '../components/Layout/FooterComponent.vue';
import HeaderComponent from '../components/Layout/HeaderComponent.vue';

export default {
  name: 'GamePickerPage',
  data: function() {
    return {
      authenticated: false,
      isLargeScreen: false,
      loading: false,
      reccTypeChosen: false,
      reccType: null
    }
  },
  components: {
    HeaderComponent,
    FooterComponent,
  },
  beforeMount: async function() {
    this.loading = true;

    // First we check screen size
    await this.checkScreenSize();

    // Attempting to get user information in case they are logged in
    await this.getUserLoginStatus();

    this.loading = false;
  },
  methods: {
    browseToReccsSelf: function() {
      this.$router.push('/recommendations-for-self');
    },
    browseToReccsForFriends: function() {
      this.$router.push('/recommendations-for-friends');
    },
    browseToReccsFromFavoriteGames: function() {
      this.$router.push('/recommendations-from-favorite-games');
    },
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > 768; // Example breakpoint
    },
    getUserLoginStatus: async function () {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_API_ROUTE}/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'  // Necessary for passing of login cookies from backend
        })

        if (response.status === 403) {
          await this.$store.dispatch('setAuth', false);
          await this.navigateToLoginPage();
        }

        const content = await response.json();

        if (content.detail === 'Unauthenticated!') {
          this.authenticated = false;
          this.navigateToLoginPage();
        } else {
          this.authenticated = true;
          this.user_id = content.id;
        }
      } catch (e) {
        this.authenticated = false;
        this.navigateToLoginPage();
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }


</style>
